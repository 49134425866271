import React, { createContext, useContext, useMemo, useRef, useState, useCallback } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

interface DynamicTaglineContextProps {
  addTagline: (el: HTMLSpanElement) => void;
  removeTagline: (el: HTMLSpanElement) => void;
  taglineHeight: number;
}

const DynamicTaglineContext = createContext<DynamicTaglineContextProps>(null!);

export const DynamicTaglineProvider: React.FC = ({ children }) => {
  const { isSSR } = useEnvironment();
  const taglinesRef = useRef<HTMLSpanElement[]>([]);
  const [taglineHeight, setTaglineHeight] = useState(40);
  const observer = useMemo(() => {
    if (!isSSR && window.ResizeObserver) {
      return new ResizeObserver(() => updateTaglineHeight());
    }
  }, [isSSR]);

  const updateTaglineHeight = () => {
    const newTaglineHeight = Math.max(...taglinesRef.current.map((el) => el.offsetHeight));
    setTaglineHeight(newTaglineHeight ?? 0);
  };

  const addTagline = useCallback(
    (el: HTMLSpanElement) => {
      if (!taglinesRef.current.includes(el)) {
        taglinesRef.current.push(el);
        observer?.observe(el);
      }
    },
    [observer],
  );

  const removeTagline = useCallback(
    (el: HTMLSpanElement) => {
      observer?.unobserve(el);
      const index = taglinesRef.current.indexOf(el);
      if (index > -1) {
        taglinesRef.current.splice(index, 1);
      }
      updateTaglineHeight();
    },
    [observer],
  );

  return (
    <DynamicTaglineContext.Provider value={{ addTagline, removeTagline, taglineHeight }}>
      {children}
    </DynamicTaglineContext.Provider>
  );
};

export const useDynamicTagline = () => useContext(DynamicTaglineContext);
